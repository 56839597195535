import React from "react";
// import img01 from "../../assets/img/images/infinaeon.svg";
// import img02 from "../../assets/img/images/about_img02.png";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion"
import sample2 from '../../assets/img/images/infvid.mp4#t=0.1';
import poster from '../../assets/img/images/infyt.jpg'
// import "node_modules/video-react/dist/video-react.css"; // import css
// import { Player } from 'video-react';
// import sample2 from '../../assets/img/images/bgvid3.mp4';
import InfLg from "../../assets/img/images/infinaeon.svg"




const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pb-30 " >
    
   
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">

            <div className="about-img wow fadeInLeft" data-wow-delay=".5s">
              {/* <img src={img01} className="mainimg" alt="" /> */}
              {/* <Player
      playsInline
      poster="/assets/poster.png"
      src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
    /> */} 
{/*     
    <video loading="lazy" controls muted playsInline poster={poster} >
    <source src={sample2}  type='video/mp4' className="about-vid"   />
</video> */}
              {/* <img src={img02} alt="" className="img-two" /> */}
           </div>
          </div>
          <div className="col-lg-12 blur aboutbr">
            
            <div className="about-content wow fadeInRight" data-wow-delay="0.7s">
              <div className="section-title mb-30">
                {/* <span className="sub-title">Who we are</span> */}
                {/* <h2 className="title text-grad">
                 About <span className="whitetxt">us </span>
                </h2> */}

                {/* <h2 className="maintitle">
                About <span className="text-grad" >Infinaeon</span>
              </h2> */}

<h2 className="maintitle"> 
  {/* <img src={InfLg} className="inflg" /> <br></br> */}

                About <span style={{color: "#20B2A9"}}>Infinaeon</span>
              </h2>

                {/* <h5>Beyond Limits, Beyond Time</h5> */}
              </div>
              <div className="aboutp2">
              <p>
              Infinaeon is an innovative Layer 2 blockchain built on Ethereum, utilizing Ethereum as its native gas currency, similar to Base and Arbitrum. This cutting-edge platform introduces a unique mechanism that sets it apart from other chains: a dynamic allocation of gas fees to a smart contract for multiple purposes. The most important being the underlying asset of the chain will always appreciate in value.
              </p>
              </div>
              {/* <Link to="/" className="btn">
               View our WhitePaper
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
