import React from "react";
import { Link } from "react-router-dom";

const WhyChooseUsItem = (props) => {
  return (

    <div className="itemwrap">
    <div className="choose-item">
 
      <div className="choose-content">
        <div className="iconwrap">
      <div className="choose-icon">
        <img src={props.item.src} alt={props.item.alt} />
      </div>
      </div>
        <h2 className="title">
          <Link to={props.item.link}>{props.item.title}</Link>
        </h2>
        
        <p className="text-grad .h9">
          <Link to={props.item.link}>{props.item.title2}</Link>
        </p>
        <p>{props.item.description}</p>
       
        <p>{props.item.description2}</p>
       
        <p>{props.item.description3}</p>
       
        <p>{props.item.description4}</p>
      </div>
    </div>
    </div>
  );
};

export default WhyChooseUsItem;
