import React from "react";
// import Banner from "../../components/Banner/Banner";
import BannerFour from "../../components/Banner/BannerFour";
import ContactOne from "../../components/Contact/ContactOne";
// import Roadmap from "../../components/Roadmap/Roadmap";
// import Sales from "../../components/Sales/Sales";
// import TeamOne from "../../components/Team/TeamOne";
// import TopPartners from "../../components/TopPartners/TopPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import Tokenomics from "../../components/WhoWeAre/Tokenomics"
import LayoutOne from "../../layouts/LayoutOne";
import TopPartners from "../../components/TopPartners/TopPartners4"
// import Swiper from "../../components/WhyChooseUs/Swiper"
// import Particles from "../../components/Particles";
import sample2 from '../../assets/img/images/bgvid3.mp4';




const Home = () => {
  return (
    <LayoutOne>
      
      <main className="fix">
    
        <BannerFour />
        <WhoWeAre />

        {/* <TopPartners /> */}
        {/* <WhyChooseUs /> */}
        <TopPartners />
        {/* <Swiper /> */}
        {/* <Sales /> */}
{/* <Tokenomics /> */}
        <div className="area-bg">
          {/* <Roadmap /> */}
          {/* <WhitePaper /> */}
        </div>
    
        {/* <TeamOne /> */}
        {/* <ContactOne /> */}
      </main>
    </LayoutOne>
  );
};

export default Home;
