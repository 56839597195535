import React from "react"

const CtaButtons = () => { 

    return (

        <>
        
        <div className="container">
          
              <div className="inlinebtn">
              <div className="spacer nospacer"></div>
                    <button  className="presalebtn"><a href="https://presale.infinaeon.com/">Presale</a></button>
                    <button className="mint"><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>


                  </div> 
        </div>
        
        </>

    );
};

export default CtaButtons;