import React from "react";
import img01 from "../../assets/img/images/infinaeon.svg";
// import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"
import sample2 from '../../assets/img/images/bgvid3.mp4';
// import "/dist/video-react.css"; // import css
import { Player } from 'video-react';





const WhoWeAre = (props) => {
  return (
    <section id="about" className="tokenomics about-area pt-130 pb-130" >


      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">

            <motion.div className="about-img wow fadeInLeft" data-wow-delay=".5s">
              {/* <img src={img01} className="mainimg" alt="" /> */}
              {/* <Player
           playsInline
             poster="/assets/poster.png"
               src={sample2}
                   /> */}
              {/* <img src={img02} alt="" className="img-two" /> */}

            </motion.div>
            <motion.div className="col-lg-12">
              <div className="about-content wow fadeInRight" data-wow-delay="0.7s">
                <div className="section-title mb-30">
                  {/* <span className="sub-title">Who we are</span> */}
                  <h2 className="title text-grad">
                    About us
                  </h2>
                  {/* <h5>Beyond Limits, Beyond Time</h5> */}
                </div>
                <p>
                  Infinaeon is an innovative Layer 2 blockchain built on Ethereum, utilizing Ethereum as its native gas currency, similar to Base and Arbitrum. This cutting-edge platform introduces a unique mechanism that sets it apart from other chains: a dynamic allocation of gas fees to a smart contract for multiple purposes. The most important being the underlying asset of the chain will always appreciate in value.
                </p>
                <Link to="/" className="btn">
                  View our WhitePaper
                </Link>
              </div>
            </motion.div>

          </div>
        </div>
        </div>
      
    </section>
  );
};

export default WhoWeAre;
