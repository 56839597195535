import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import YouTube from "../../assets/img/images/youtube.png"
// import './styles.css';

// import required modules
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css/scrollbar';




export default function App() {
  return (


    <>





      {/* <div className="articleh1">

<h1>Featured In</h1>

</div> */}
      <div className='container ytsection'>

        <div className="ytimage">

          <img src={YouTube} alt="YouTube" />

        </div>
        <div className='spacer'></div>

        <Swiper 
          // autoplay={{
          //     delay: 2500,
          //     disableOnInteraction: false,
          // }}
        
          loop={"true"}
          loopedSlides={2}
          spaceBetween={30}
          slidesPerView={2}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            400: {
              slidesPerView: 1,
            },
            639: {
              slidesPerView: 1,
            },
            865: {
              slidesPerView: 1
            },
            1000: {
              slidesPerView: 2
            },
            1500: {
              slidesPerView: 2
            },
            1700: {
              slidesPerView: 2
            }
          }}




          // slidesPerView={2} 

          scrollbar={{ draggable: true }} navigation={true} modules={[Navigation, Autoplay]} className="mySwiper">
          <div className=''>
          <SwiperSlide>
       
       {/* width="560" height="315"  */}


       <iframe className='yt-embed' src="https://www.youtube.com/embed/a2p2Sjv-ZJc?si=PpaT_xGvc_1-iwks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </SwiperSlide>
          <SwiperSlide>
       
       {/* width="560" height="315"  */}


       <iframe  className='yt-embed' src="https://www.youtube.com/embed/beMdiWUhzOg?si=tb2qsCnjNFcdKggZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </SwiperSlide>
          <SwiperSlide>
       
       {/* width="560" height="315"  */}


       <iframe className='yt-embed'  src="https://www.youtube.com/embed/22vnC7dat6U?si=ZtZApGJoYi0cMCkP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </SwiperSlide>


          <SwiperSlide>
       
       {/* width="560" height="315"  */}


       <iframe className='yt-embed'  src="https://www.youtube.com/embed/icZtE7IaiXw?si=TeTXhrw8Q-YFgYtO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </SwiperSlide>

          <SwiperSlide>
       
          {/* width="560" height="315"  */}


          <iframe className='yt-embed' src="https://www.youtube.com/embed/IQ3XLXjTQ-g?si=Fn3EXBQM5IoLz3qP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
   
     </SwiperSlide>
          <SwiperSlide>
       


            <iframe    src="https://www.youtube.com/embed/bluTGqXbPYg?si=unS_pPiMeRVcY88v" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        
          </SwiperSlide>
          <SwiperSlide>
            <iframe  src="https://www.youtube.com/embed/W01oU6zJ3GY?si=1_mP5TIBDPMxBkj6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </SwiperSlide>
          <SwiperSlide>
            <iframe  src="https://www.youtube.com/embed/FEzed7QVlvo?si=9jFbpCQS4he1CefU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </SwiperSlide>
          <SwiperSlide>
            <iframe  src="https://www.youtube.com/embed/P6birVXbIk4?si=flxmGFDjoFLjoy5V" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </SwiperSlide>

{/* 
          <SwiperSlide>
            <iframe  src="https://www.youtube.com/embed/j2gLJTJExew?si=-ozJ6kn9C8DPkGqS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </SwiperSlide> */}
          <SwiperSlide>
            <iframe  src="https://www.youtube.com/embed/WIrsrBWes9g?si=IJwWZMyKO93UKisc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


          </SwiperSlide>
          <SwiperSlide>
          <iframe  src="https://www.youtube.com/embed/6QOiSe9inKk?si=U35S-YgyjoZ28-lC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

          </SwiperSlide>
          <SwiperSlide>
          <iframe  src="https://www.youtube.com/embed/uLCAJ1RnHSc?si=KzcdHixNOr41vxfp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                        

          </SwiperSlide>
          </div>
          {/* <SwiperSlide>Slide 9</SwiperSlide> */}
        </Swiper>

 
      </div>
     
          
    </>
  );
}
