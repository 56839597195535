import React from "react";
// import Banner from "../../components/Banner/Banner";
import BannerFour from "../../components/Banner/BannerFive";
import ContactOne from "../../components/Contact/ContactOne";
// import Roadmap from "../../components/Roadmap/Roadmap";
// import Sales from "../../components/Sales/Sales";
// import TeamOne from "../../components/Team/TeamOne";
// import TopPartners from "../../components/TopPartners/TopPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhoWeAre2 from "../../components/WhoWeAre/WhoWeAre2";

import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import Tokenomics from "../../components/WhoWeAre/Tokenomics"
import LayoutOne from "../../layouts/LayoutOne";
import TopPartners from "../../components/TopPartners/TopPartners4"
// import Swiper from "../../components/WhyChooseUs/Swiper"
// import Particles from "../../components/Particles";
import sample2 from '../../assets/img/images/bgvid3.mp4';
import Articles from "../../components/Articles/Articles"
import ArticlesNews from "../../components/Articles/ArticlesNews"
import YouTube from "../../components/Articles/YouTube"
import ArticleCards from "../../components/Articles/ArticleCards"
// import Countdown from "../../components/CountDown/CountDownOne";
// import Ecosystem from "../../components/Ecosystem/Ecosystem";
import FooterOne from "../../components/Footer/FooterOne";
import HeaderOne from "../../components/Header/HeaderOne";
import InfoGraphic from "../../components/InfoGraphic/InfoGraphic"


const HomeFour = () => {
  return (
    // <LayoutOne>
   
      
      <main className="fix">
        <div className="bg-3"></div>
    <div className="vhheight">
    <HeaderOne />
        <BannerFour />
        </div>
        {/* <Ecosystem /> */}
        {/* <Countdown /> */}
        <ArticleCards />
        <WhoWeAre2 />
        {/* <InfoGraphic /> */}
      
        {/* <Articles /> */}
     
        <YouTube />

        {/* <TopPartners /> */}
        {/* <WhyChooseUs /> */}
        <TopPartners />
       
        {/* <Swiper /> */}
        {/* <Sales /> */}
{/* <Tokenomics /> */}
        <div className="area-bg">
          {/* <Roadmap /> */}
          {/* <WhitePaper /> */}
        </div>
    <FooterOne />
        {/* <TeamOne /> */}
        {/* <ContactOne /> */}
      </main>
    // </LayoutOne>
  );
};

export default HomeFour;
