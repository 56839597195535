import React, { useState, useEffect, useRef } from "react";
import FooterOne from "../components/Footer/FooterOne";
import HeaderOne from "../components/Header/HeaderOne";
// import PageLoader from "../components/PageLoader/PageLoader";

import cn from "classnames";



// import WAVE from 'vanta/dist/vanta.waves.min.js'

// const LayoutOne = (props) => {
//   const [vantaEffect, setVantaEffect] = useState(null)
//   const myRef = useRef(null)
//   useEffect(() => {
//     if (!vantaEffect) {
//       setVantaEffect(WAVE({
//         el: myRef.current,
//          mouseControls: false,
//   touchControls: true,
//   gyroControls: false,
//   maxHeight: 100.00,
//   minWidth: 100.00,
//   scale: 1,
//   scaleMobile: 0.5,
//   color: 0x1e1e,
//   shininess: 50.00,
//   waveHeight: 10.00,
//   waveSpeed: 1.30,
//   zoom: 0.02,

//       }))
//     }
//     return () => {
//       if (vantaEffect) vantaEffect.destroy()
//     }
//   }, [vantaEffect])
//   return <div ref={myRef}>
//     <div className={cn("")}>
    

//       <HeaderOne />

//       {props.children}

//       <FooterOne />
//     </div>
//   </div>
// }


const LayoutOne = (props) => {
  return (
    <div className={cn("")}>
      {/* <PageLoader /> */}

      <HeaderOne />

      {props.children}

      <FooterOne />
    </div>
  );
};

export default LayoutOne;
