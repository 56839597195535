import React from "react";
import Countdown from "react-countdown";
import ProblemImg from "../../assets/img/images/problemimg.jpg"
import PresaleMethods from "../TopSliders/PresaleMethods"

const ProblemVideo = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>hours
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>minutes
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>seconds
          </div>
        </>
      );
    }
  };

  return (
    <section id="countdown" className="countdown-area-two">
      <div className="container custom-container-four">
        <div className="row">
          <div className="col-lg-12">
            <div className="countdown-wrap">
              {/* <h2 className="title2">Problem Statement:</h2> */}
              <div id="countdown-gampang"></div>
              <a href="https://www.youtube.com/watch?v=icZtE7IaiXw">
    <img src={ProblemImg} className="problemimg" style={{marginBottom: "10px"}} /></a> 
    <PresaleMethods />
              {/* <div className="custom-countdown-two">
                <Countdown date={Date.now() + 5000000} renderer={renderer} />
              </div> */}
              {/* <div className="inlinebtn">
                    <button style={{ marginRight: "20px" }} className="presalebtn"><a href="https://presale.infinaeon.com/">Presale</a></button>
                    <button className="mint"><a href="https://mint.operationphoenix.network">Mint NFTs</a></button>


                  </div> */}
            </div>
          </div>
          
        </div>
      </div>
      
    </section>
  );
};

export default ProblemVideo;
