import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import YouTube from "../../assets/img/images/youtube.png"
// import './styles.css';

// import required modules
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css/scrollbar';

import Article1 from "../../assets/img/images/articleimg.jpg"
import Bitcoinist from "../../assets/img/images/bitcoinistlg.webp"
import Article2 from "../../assets/img/images/coinchapter.webp"
import CoinChapter from "../../assets/img/images/coinchapterlg.png"
import Article3 from "../../assets/img/images/coingabbar.webp"
import CoinGabbar from "../../assets/img/images/coingabbarlg.svg"
import Article4 from "../../assets/img/images/infassure.webp"
import Assure from "../../assets/img/images/assurelg.png"
import Article5 from "../../assets/img/images/mearticle.jpg"
import MeLg from "../../assets/img/images/mearticlelg.png"
import Article6 from "../../assets/img/images/nftevearticle.jpg"
import NftLg from "../../assets/img/images/nftevelg.svg"
import Article7 from "../../assets/img/images/ambarticle.webp"
import AmbLg from "../../assets/img/images/amblg2.webp"
import Article8 from "../../assets/img/images/coineditionarticle.jpg"
import CeLg from "../../assets/img/images/coineditionlg.png"
import Article9 from "../../assets/img/images/beinarticle.webp"
import BeinLg from "../../assets/img/images/beinlg.svg"
import Article10 from "../../assets/img/images/zyarticle.jpg"
import ZyLg from "../../assets/img/images/zylg.png"
import Article11 from "../../assets/img/images/brarticle.jpg"
import BrLg from "../../assets/img/images/brlg.png"
import Article12 from "../../assets/img/images/cparticle2.webp"
import CpLg from "../../assets/img/images/cplg.svg"
import Article14 from "../../assets/img/images/cwarticle.jpg"
import CwLg from "../../assets/img/images/cwlg.svg"
import Article15 from "../../assets/img/images/bravearticle.jpg"
import BraveLg from "../../assets/img/images/bravelg.svg"
import Article16 from "../../assets/img/images/blockarticle.webp"
import BlockoLg from "../../assets/img/images/blocklg.webp"
import Article17 from "../../assets/img/images/techbarticle.jpg"
import TechLg from "../../assets/img/images/techblg.png"
import Article18 from "../../assets/img/images/utbarticle.webp"
import UtbLg from "../../assets/img/images/utblg.webp"
import Article19 from "../../assets/img/images/cryparticle.png"
import CrypLg from "../../assets/img/images/cryplg2.svg"
import Article20 from "../../assets/img/images/portarticle.webp"
import PortLg from "../../assets/img/images/portlg.svg"
import Article21 from "../../assets/img/images/finarticle.jpg"
import FinLg from "../../assets/img/images/finlg.webp"
import Article22 from "../../assets/img/images/rsarticle.webp"
import RsLg from "../../assets/img/images/rslg.webp"
import Article23 from "../../assets/img/images/aiarticle.jpg"
import AiLg from "../../assets/img/images/ailg.png"
import Article24 from "../../assets/img/images/kryparticle.png"
import KrypLg from "../../assets/img/images/kryplg.png"

export default function App() {
  return (


    <>





      {/* <div className="articleh1">

<h1>Featured In</h1>

</div> */}
<div className='wrapper'>
      <div className='container articlewrap'>

        <div className="articleh1">

          {/* <h1>Featured <span className="text-grad" >in</span></h1> */}
          
          <h1>Featured in</h1>


        </div>
        <div className='spacer'></div>
        <Swiper
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}

        
        
       
          loop={"true"}
          loopedSlides={3}
          spaceBetween={10}
          slidesPerView={3}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            400: {
              slidesPerView: 1,
            },
            639: {
              slidesPerView: 1,
            },
            865: {
              slidesPerView: 2,
                        },
            1000: {
              slidesPerView: 3.
            },
            1500: {
              slidesPerView: 3,
            },
            1700: {
              slidesPerView: 3
            }
          }}




          // slidesPerView={2} 



          
          navigation={true} modules={[Navigation, Autoplay]} className="mySwiper">
             <div> 

           

             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article24} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "20px", lineHeight: "23px"}}>SUI Price Rally Continues as Traders Suggest Infinaeon Could Save Ethereum</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://www.kryptomoney.com/sui-price-rally-continues-as-traders-suggest-infinaeon-could-save-ethereum/" class="btn btn-primary">View Article</a>
             <img src={KrypLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>


             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article23} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "20px", lineHeight: "23px"}}>Tron Bull Price Surges, Crypto Market Recovers, and Infinaeon Presale Takes Off</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://www.analyticsinsight.net/cryptocurrency-analytics-insight/tron-bull-price-surges-crypto-market-recovers-and-infinaeon-presale-takes-off" class="btn btn-primary">View Article</a>
             <img src={AiLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article22} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "20px", lineHeight: "23px"}}>The Injective Price Rally Surprises Traders, But Infinaeon Could Offer Far Bigger Returns</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://researchsnipers.com/the-injective-price-rally-surprises-traders-but-infinaeon-could-offer-far-bigger-returns/" class="btn btn-primary">View Article</a>
             <img src={RsLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article21} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "23px", lineHeight: "27px"}}>DogWifHat Price Recovers Following Slump, Infinaeon Presale Heats Up</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://financefeeds.com/dogwifhat-price-recovers-following-slump-infinaeon-presale-heats-up/" class="btn btn-primary">View Article</a>
             <img src={FinLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

               <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article20} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "18px", lineHeight: "22px"}}>AAVE Price Surges and The DeFi Community Turns To Infinaeon For Ethereum Scaling</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://www.theportugalnews.com/news/2024-09-09/aave-price-surges-and-the-defi-community-turns-to-infinaeon-for-ethereum-scaling/91960" class="btn btn-primary">View Article</a>
             <img src={PortLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article19} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "22px", lineHeight: "26px"}}>Ethereum Price Crash Puts Infinaeon In The Spotlight</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://cryptwerk.com/post/ethereum-price-crash-puts-infinaeon-in-the-spotlight/" class="btn btn-primary">View Article</a>
             <img src={CrypLg} className='brandimg blackfilter' />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article18} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "18px", lineHeight: "18px"}}>Toncoin Price Recovers Following Telegram News and Infinaeon Gears Up For Layer-2 Dominance</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://usethebitcoin.com/crypto/toncoin-price-recovers-following-telegram-news-and-infinaeon-gears-up-for-layer-2-dominance/" class="btn btn-primary">View Article</a>
             <img src={UtbLg} style={{marginBottom: "20px!important"}} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article17} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "20px", lineHeight: "24px"}}>Crypto Prices Turn Red In September, But Infinaeon Pushes On With Unique Layer-2 Utility</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://techbullion.com/crypto-prices-turn-red-in-september-but-infinaeon-pushes-on-with-unique-layer-2-utility/" class="btn btn-primary">View Article</a>
             <img src={TechLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

              
             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article16} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "20px", lineHeight: "24px"}}>SUNDOG Price Crashes: Is Memecoin Mania Giving Way to Serious Projects Like Infinaeon?</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://blockonomi.com/sundog-price-crashes-is-memecoin-mania-giving-way-to-serious-projects-like-infinaeon/" class="btn btn-primary">View Article</a>
             <img src={BlockoLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article15} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "22px", lineHeight: "26px"}}>ARB Slides, SUI Price Recovers, and Infinaeon’s Gas Fee Solution Turns Heads</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://bravenewcoin.com/insights/arb-slides-sui-price-recovers-and-infinaeons-gas-fee-solution-turns-heads" class="btn btn-primary">View Article</a>
             <img src={BraveLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article14} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "20px", lineHeight: "24px"}}>Popcat Price Surges and Infinaeon Impresses With Innovative Layer-2 Solutions</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://www.cryptowisser.com/press-releases/popcat-and-infinaeon-buck-bearish-trend/" class="btn btn-primary">View Article</a>
             <img src={CwLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article12} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "22px", lineHeight: "26px"}}>Solana Price Tumbles 20%, Broader Market Struggles, But Infinaeon Hype Grows</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://coinpedia.org/guest-post/solana-price-tumbles-20-broader-market-struggles-but-infinaeon-hype-grows/" class="btn btn-primary">View Article</a>
             <img src={CpLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article11} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "26px", lineHeight: "32px"}}>Infinaeon Presale Hype Surges as Tron Price Rally Hits Resistance</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://blockchainreporter.net/infinaeon-presale-hype-surges-as-tron-price-rally-hits-resistance/" class="btn btn-primary">View Article</a>
             <img src={BrLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article10} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "22px", lineHeight: "36px"}}>Helium Price Recovers & Infinaeon Crypto Bridge Draws Praise</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://zycrypto.com/helium-price-recovers-infinaeon-crypto-bridge-draws-praise/" class="btn btn-primary">View Article</a>
             <img src={ZyLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
             <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article9} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text"style={{fontSize: "26px", lineHeight: "41px"}}>Infinaeon Launches Highly Anticipated Presale</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://beincrypto.com/infinaeon-launches-highly-anticipated-presale/" class="btn btn-primary">View Article</a>
             <img src={BeinLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
          </div>
            
               <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article1} alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title"></h5>
                <p class="card-text" style={{fontSize: "26px", lineHeight: "41px"}}>Infinaeon Launches Highly Anticipated Presale</p> 
                {/* <div className='spacer'></div> */}
                <a href="https://bitcoinist.com/infinaeon-launches-highly-anticipated-presale/" class="btn btn-primary">View Article</a>
             <img src={Bitcoinist} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='swiperheight'>
            <div class="card" >
            <img class="card-img-top" src={Article4} alt="Card image cap" />
              <div class="card-body">
             
                <p class="card-text" style={{fontSize: "14px", lineHeight: "21px"}}>INFINAEON has now been APPROVED and has completed the KYC verification process with The Verification Gold Standard®, Assure DeFi®.</p>
                <a href="https://assuredefi.medium.com/infinaeon-has-now-been-approved-and-has-completed-the-kyc-verification-process-with-the-ccc925d1a764" class="btn btn-primary">View Article</a>
                <img src={Assure} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
        
          <SwiperSlide className='swiperheight'>
            <div class="card" >
            <img class="card-img-top" src={Article3} alt="Card image cap" />
              <div class="card-body">
             
                <p class="card-text" style={{fontSize: "20px", lineHeight: "32px"}}>Neiro on ETH Price Crashes 40% as Investors Flock to Infinaeon Presale</p>
                <a href="https://www.coingabbar.com/en/crypto-press-release/infinaeon-presale-surges-as-neiro-price-plummets" class="btn btn-primary">View Article</a>
                <img src={CoinGabbar} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='swiperheight'>
            <div class="card" >
            <img class="card-img-top" src={Article8} alt="Card image cap" />
              <div class="card-body">
             
                <p class="card-text" style={{fontSize: "18px", lineHeight: "24px"}}>Memecoin Price Crash Hits PEPE, SHIB, and DOGE as Infinaeon Token Burn Excites Presale Market</p>
                <a href="https://coinedition.com/memecoin-price-crash-hits-pepe-shib-and-doge-as-infinaeon-token-burn-excites-presale-market/" class="btn btn-primary">View Article</a>
                <img src={CeLg} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='swiperheight'>
            <div class="card" >
            <img class="card-img-top" src={Article5} alt="Card image cap" />
              <div class="card-body">
             
                <p class="card-text" style={{fontSize: "20px", lineHeight: "25px"}}>Infinaeon Becomes Most Promising Presale as Polygon Price Action Disappoints Bulls</p>
                <a href="https://themerkle.com/infinaeon-becomes-most-promising-presale-as-polygon-price-action-disappoints-bulls/" class="btn btn-primary">View Article</a>
                <img src={MeLg} className='brandimg2' />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='swiperheight'>
            <div class="card" >
            <img class="card-img-top" src={Article6} alt="Card image cap" />
              <div class="card-body">
             
                <p class="card-text" style={{fontSize: "20px", lineHeight: "25px"}}>Arbitrum Price Struggles While Infinaeon’s Tech and Presale Fires Up The Market</p>
                <a href="https://nftevening.com/arbitrum-price-struggles-while-infinaeons-tech-and-presale-fires-up-the-market/" class="btn btn-primary">View Article</a>
                <img src={NftLg} className='brandimg2' />
              </div>
            </div>
          </SwiperSlide>

   
       
          <SwiperSlide className='swiperheight'>
            <div class="card" >
              <img class="card-img-top" src={Article2} alt="Card image cap" />
              <div class="card-body">
              
                <p class="card-text" style={{ lineHeight: "31px"}}>Memecoin Frenzy Hits TRON As Traders Roll Profits Into The Infinaeon Presale.</p>
                <a href="#" class="btn btn-primary">View Article</a>
                <img src={CoinChapter} className='brandimg' />
              </div>
            </div>
          </SwiperSlide>
      
       
         

          {/* 
          <SwiperSlide>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/j2gLJTJExew?si=-ozJ6kn9C8DPkGqS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div class="card" >
              <img class="card-img-top" src="..." alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
              </div>
            </div>

          </SwiperSlide>
          <SwiperSlide>
            <div class="card" >
              <img class="card-img-top" src="..." alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div class="card" >
              <img class="card-img-top" src="..." alt="Card image cap" />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>Slide 9</SwiperSlide> */}
        </Swiper>
      </div>
      </div>
    </>
  );
}
